<template>
    <b-button variant="primary" class="float-right" @click="onDelete">
        Löschen
    </b-button>
</template>

<script>
import { FirewallRuleSet } from '@/core/services/store/models/firewall_rule_set'
import projectMixin from '@/core/mixins/projectMixin'

export default {
    name: 'FirewallRuleSetActions',
    mixins: [projectMixin],
    props: {
        ruleSet: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        async onDelete() {
            const id = this.ruleSet.id
            await this.api.deleteFirewallRuleSet(id)
            await this.$router.push({ name: 'projects-single', params: { uuid: this.project.uuid } })
            await FirewallRuleSet.delete(id)
        }
    }
}
</script>

<style scoped>

</style>
