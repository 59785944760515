<template>
    <div>
        <luma-button v-if="rules.length"
                     title="Regel erstellen"
                     type="submit"
                     :disabled="$wait.waiting('create-rule')"
                     variant="primary"
                     class="mt-6 mb-6"
                     @click="modals.create = true"
        />

        <div v-if="!rules.length" class="text-center text-muted mt-40">
            <span class="font-weight-bold">
                Das Regelset hat momentan noch keine Regeln.
            </span>

            <div>
                <!-- primary action -->
                <luma-button title="Regel erstellen"
                             type="submit"
                             variant="primary"
                             class="mt-6 mb-3"
                             @click="modals.create = true"
                />
            </div>
        </div>

        <!-- table -->
        <table v-if="rules.length" class="table table-striped table-responsive-md">
            <thead>
                <tr>
                    <th v-for="{ label, key } in mappedFields"
                        :key="key"
                        :class="{'text-center': key === 'actions'}"
                    >
                        {{ label }}
                    </th>
                </tr>
            </thead>

            <!-- draggable component: https://github.com/SortableJS/Vue.Draggable -->
            <draggable v-model="rules"
                       tag="tbody"
                       v-bind="dragOptions"
                       @start="dragging = true"
                       @ende="dragging = false"
                       @change="changed"
            >
                <tr v-for="item in rules"
                    :key="item.id"
                    class="rules"
                >
                    <td v-for="({ key }) in mappedFields" :key="key">
                        {{ item[key] }}
                        <i v-if="key === 'actions'" class="fa fas fa-bars d-block text-center" />
                    </td>
                </tr>
            </draggable>
        </table>

        <b-modal v-if="modals.create"
                 id="file-details"
                 v-model="modals.create"
                 size="lg"
                 :hide-footer="true"
                 :hide-header="true"
                 centered
        >
            <FirewallRuleSetRuleModal v-model="localRule" @close="modals.create = false" @create="createRule" />
        </b-modal>
    </div>
</template>

<script>
import LumaButton from '@/view/components/helper/LumaButton'
import FirewallRuleSetRuleModal from '@/view/components/firewall_rule_sets/modals/FirewallRuleSetRuleModal'
import { FirewallRule } from '@/core/services/store/models/firewall_rule'
import { waitFor } from 'vue-wait'
import draggable from 'vuedraggable'

export default {
    name: 'FirewallRuleSetRules',
    components: { FirewallRuleSetRuleModal, LumaButton, draggable },
    props: {
        ruleSet: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            fields: [
                'title',
                'direction',
                'action',
                'interface',
                'source_address',
                'destination_address',
                'protocol',
                'actions'
            ],
            localRule: new FirewallRule({
                direction: 'IN',
                action: 'ACCEPT',
                protocol: 'tcp',
                interface: 'net0',
                source_address_set_id: -1,
                destination_address_set_id: -1,
                id: -1
            }),
            modals: { create: false },
            dragging: false
        }
    },
    computed: {
        rules() {
            const rules = FirewallRule.all() || []
            rules.sort((a, b) => a.order > b.order)
            return rules
        },
        /*
         * @description: options for drag and drop
         */
        dragOptions () {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        },
        mappedFields () {
            return this.fields.map(el => ({
                key: el,
                label: this.$t(`DATACENTER.VSERVERS.DETAILS.FIREWALL.COLUMNS.${el.toUpperCase()}`)
            }))
        }
    },
    methods: {
        /*
         * @description: Creates a rule for the current ruleset
         */
        createRule: waitFor('firewall-create', async function () {
            for (const [key, value] of Object.entries(this.localRule)) {
                if (value === -1) { delete this.localRule[key] }
            }
            await FirewallRule.createById(this.$route.params.id, this.localRule)
            this.modals.create = false
        }),

        /*
         * @description: Deletes selected rule for the current ruleset
         */
        deleteRule: waitFor('firewall-delete', async function ({ id }) {
            await FirewallRule.deleteById(this.$route.params.id, id)
        }),
        /*
         * @description: change event for <draggable>
         * @params: {moved: {*}, removed: {*}, added: {*}}
         * @docs: https://github.com/SortableJS/Vue.Draggable
         */
        async changed ({ moved }) {
            if (!moved) return

            console.log(moved)

            await FirewallRule.updateById(this.ruleSet.id, moved.element?.id, {
                order: this.rules[moved.newIndex].order + 1
            })
        }
    },
    watch: {
        '$route.params.id': {
            handler: async function (id) { await FirewallRule.fetchById(id) },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
